export default [
    {
        label: '新潟支店',
        src: 'branch_new/niigata/index.html'
      },
      {
        label: '長岡支店',
        src: 'branch_new/nagaoka/index.html'
      },
      {
        label: '山梨支店',
        src: 'branch_new/yamanashi/index.html'
      },
      {
        label: '長野支店',
        src: 'branch_new/nagano/index.html'
      },
      {
        label: '富山支店',
        src: 'branch_new/toyama/index.html'
      },
      {
        label: '石川支店',
        src: 'branch_new/ishikawa/index.html'
      },
      {
        label: '福井支店',
        src: 'branch_new/fukui/index.html'
      },
]