export default [
    // {
    //     label: '本社部門',
    //     src: 'branch_new/honsha_office/index.html'
    // },
    // {
    //     label: '大阪オフイス',
    //     src: 'branch_new/osaka_office/index.html'
    // },
    {
        label: '滋賀支店',
        src: 'branch_new/shiga/index.html'
    },
    {
        label: '京都支店',
        src: 'branch_new/kyoto/index.html'
    },
    {
        label: '奈良支店',
        src: 'branch_new/nara/index.html'
    },
    {
        label: '奈良整備工場',
        src: 'https://fujimaintenanceteam.com/mnt_fuji_nara.html'
    },

    {
        label: '大阪支店',
        src: 'branch_new/osaka/index.html'
    },
    {
        label: '関西空港支店',
        src: 'branch_new/kansai_airport/index.html'
    },
    {
        label: '阪神支店',
        src: 'branch_new/hanshin/index.html'
    },
    {
        label: '阪神コールド支店',
        src: 'branch_new/hanshin_cold/index.html'
    },
    {
        label: '兵庫支店',
        src: 'branch_new/hyogo/index.html'
    },
    {
        label: '淡路準備室',
        src: 'branch_new/awaji/index.html'
    },
    {
        label: '姫路支店',
        src: 'branch_new/himeji/index.html'
    },
]