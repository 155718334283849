import facebook from '../../../../assets/img/social_media/facebook.png';
// import twitter from '../../../../assets/img/social_media/twitter.png';
import instagram from '../../../../assets/img/social_media/instagram.png';
import youtube from '../../../../assets/img/social_media/youtube.png';
import tiktok from '../../../../assets/img/social_media/tiktok.png';
import truckgirl from '../../../../assets/img/social_media/tragirl.png';

export default [
    {
        href: 'https://ja-jp.facebook.com/fujitransport/',
        src: facebook,
        alt: 'Facebook logo'
    },
    // {
    //     href: 'https://twitter.com/fuji_bluewhale',
    //     src: twitter,
    //     alt: 'Twitter logo'
    // },
    {
        href: 'https://www.instagram.com/fujitransport_official/?hl=ja',
        src: instagram,
        alt: 'Instagram logo'
    },
    {
        href: 'https://www.youtube.com/channel/UCD_TCADHCgzMbTULTbr8HJA',
        src: youtube,
        alt: 'YouTube logo'
    },
    {
        href: 'https://www.tiktok.com/@fujitransport?',
        src: tiktok,
        alt: 'Tiktok logo'
    },
    // {
    //     href: 'https://www.mlit.go.jp/jidosha/tragirl/tobe/case1/',
    //     src: truckgirl,
    //     alt: 'Truck Girl logo'
    // },
    // {
    //     href: 'https://job.mynavi.jp/23/pc/search/corp209014/outline.html',
    //     src: 'https://job.mynavi.jp/conts/kigyo/2023/logo/banner_entry_160_45.gif',
    //     alt: 'マイナビ2023',
    //     class: 'mynavi-width'
    // },
]