<template>
  <section class="social-media main-section of-hidden" >
    <a v-for="content, index in socialMediaContent" :key="index" :href="content.href" :class="content.class" target="_blank">
      <img
        :src="content.src"
        :alt="content.alt"
      />
    </a>
    <a  href="https://www.mlit.go.jp/jidosha/tragirl/tobe/case1/">
       <img class="truck-girl"
        src="../../../../assets/img/social_media/tragirl.png"
        alt="Truck Girl logo"
      />
    </a>
  </section>
   
</template>

<script>
import socialMediaContent from './content.js';

export default {
    data() {
        return {
            socialMediaContent: socialMediaContent
        }
    },
}
</script>
<style scoped>
  .truck-girl{          
    height: 3rem;
    width: 3rem;
    position: relative;
    top: -4px;
  }

</style>