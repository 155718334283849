export default [
    {
        label: '九州オフイス',
        src: 'branch_new/kyushu_office/index.html'
    },
    {
        label: '北九州支店',
        src: 'branch_new/kitakyushu/index.html'
    },
    {
        label: '福岡支店',
        src: 'branch_new/fukuoka/index.html'
    },
    {
        label: '大川支店',
        src: 'branch_new/okawa2/index.html'
    },
    {
        label: 'クロスドックセンター',
        src: 'branch_new/okawa/index.html'
    },
    {
        label: '九州コールド支店',
        src: 'branch_new/kyushu_cold/index.html'
    },
    {
        label: '大牟田支店',
        src: 'branch_new/omuta/index.html'
    },
    {
        label: '熊本支店',
        src: 'branch_new/kumamoto/index.html'
    },
    {
        label: '大分支店',
        src: 'branch_new/oita/index.html'
    },
    {
        label: '長崎支店',
        src: 'branch_new/nagasaki/index.html'
    },
    {
        label: '都城支店',
        src: 'branch_new/miyakonojyo/index.html'
    },
    {
        label: '鹿児島支店',
        src: 'branch_new/kagoshima/index.html'
    },
]