<template>
  <li class="dropdown">
    <div
      type="button"
      class="dropdown-button nav-item webkit-none"
      aria-expanded="true"
      aria-haspopup="true"
    >
      <span>{{ label }}</span>

      <chevron-down />
      <!-- <chevron-down v-show="!isMobile" /> -->
      <!-- <chevron-right v-show="isMobile" /> -->
    </div>

    <div
      class="dropdown-menu"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
    >
      <div class="py-1" role="none">
        <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
        <div v-for="(menuItem, index) in menuItems" :key="index">
          <button
            v-if="menuItem.type == 'MODAL'"
            class="dropdown-menu-item"
            role="menuitem"
            tabindex="-1"
            @click="sendPage(menuItem.src)"
          >
            {{ menuItem.label }}
          </button>
          <a
            v-if="menuItem.type == 'LINK'"
            target="_blank"
            :href="menuItem.href ? menuItem.href : '#'"
            class="dropdown-menu-item"
            role="menuitem"
            tabindex="-1"
            >{{ menuItem.label }}</a
          >
          <div
            v-if="menuItem.type == 'SUBMENU'"
            class="dropdown-menu-item sub-dropdown"
          >
            <div class="flex justify-between items-center">
              <div>{{ menuItem.label }}</div>
              
              <!-- <chevron-down v-show="isMobile" />
              <chevron-right v-show="!isMobile" /> -->
              <chevron-right />
            </div>
            <div class="sub-dropdown-menu">
              <button
                v-for="(submenuItem, index) in menuItem.submenu"
                :key="index"
                class="dropdown-menu-item"
                role="menuitem"
                tabindex="-1"
                @click="sendPage(submenuItem.src)"
              >
                {{ submenuItem.label }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ChevronDown from './ChevronDown.vue'
import ChevronRight from './ChevronRight.vue'

export default {
  components: { 
    ChevronDown,
    ChevronRight 
  },
  props: {
    label: {
      type: String,
    },
    menuItems: {
      type: Array,
    },
  },
  data() {
    return {
      // isMobile: false,
      // mobileBreakpoint: 768, // equivalent to tailwind css md: breakpoint
    }
  },
  mounted() {
    // this.checkIfIsMobile();

    // window.addEventListener('resize', this.checkIfIsMobile);
  },
  methods: {
    sendPage(src) {
      this.$parent.$emit("modal-content", src);
    },
    // checkIfIsMobile() {
    //   this.isMobile = window.innerWidth < this.mobileBreakpoint;
    // },
  },
};
</script>