import image1 from '../../../../assets/img/carousel/image1.jpg';
import image2 from '../../../../assets/img/carousel/image2.jpg';
import image3 from '../../../../assets/img/carousel/image3.jpg';
import image4 from '../../../../assets/img/carousel/image4.jpg';
import image5 from '../../../../assets/img/carousel/image5.jpg';
import image6 from '../../../../assets/img/carousel/image6.jpg';
import image7 from '../../../../assets/img/carousel/image7.jpg';
import image9 from '../../../../assets/img/carousel/image9.jpg';
import image10 from '../../../../assets/img/carousel/image10.jpg';
import image11 from '../../../../assets/img/carousel/image11.jpg';
import image14 from '../../../../assets/img/carousel/image14.jpg';
import image16 from '../../../../assets/img/carousel/image16.jpg';
import image17 from '../../../../assets/img/carousel/image17.jpg';
import ISO from '../../../../assets/img/carousel/ISO_certificate.jpg';

export default [
    {
        src: image1,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: image2,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: image17,
        alt: 'ジャパントラックショー'
    },
    {
        src: image3,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: image4,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: image5,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: image6,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: image7,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: image9,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: image10,
        alt: 'フジトランスポートのトラックと富士山'
    },
    {
        src: image11,
        alt: 'フジトランスポートの事務所'
    },
    {
        src: image16,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: image14,
        alt: 'フジトランスポートのトラック'
    },
    {
        src: ISO,
        alt: 'ISO39001 合成'
    },
]
