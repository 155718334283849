export default [
    {
        label: '名古屋支店',
        src: 'branch_new/nagoya/index.html'
    },
    {
        label: '豊川支店',
        src: 'branch_new/toyokawa/index.html'
    },
    {
        label: 'アイカ支店',
        src: 'branch_new/aika/index.html'
    },
    {
        label: '岐阜支店',
        src: 'branch_new/gifu/index.html'
    },
    {
        label: 'セントレア支店',
        src: 'branch_new/centrair/index.html'
    },
    {
        label: '三重支店',
        src: 'branch_new/mie/index.html'
    },
]