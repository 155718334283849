export default [
    {
        label: '青森支店',
        src: 'branch_new/aomori/index.html'
    },
    {
        label: '秋田支店',
        src: 'branch_new/akita/index.html'
    },
    {
        label: '岩手支店',
        src: 'branch_new/iwate/index.html'
    },
    {
        label: '仙台支店',
        src: 'branch_new/sendai/index.html'
    },
    {
        label: '郡山支店',
        src: 'branch_new/fukushima/index.html'
    },
    {
        label: '山形支店',
        src: 'branch_new/yamagata/index.html'
    },
    {
        label: 'いわき支店',
        src: 'branch_new/iwaki/index.html'
    },
];