export default [
    {
        label: '東京オフイス',
        src: 'branch_new/tokyo/index.html'
    },
    // {
    //     label: '羽田オフイス',
    //     src: 'branch/haneda.html'
    // },
    {
        label: '栃木支店',
        src: 'branch_new/tochigi/index.html'
    },
    {
        label: '茨城支店',
        src: 'branch_new/ibaraki/index.html'
    },
    {
        label: '鹿島支店',
        src: 'branch_new/kashima/index.html'
    },
    {
        label: '成田支店',
        src: 'branch_new/narita/index.html'
    },
    {
        label: '成田整備工場',
        src: 'https://fujimaintenanceteam.com/mnt_fuji_narita.html'
    },
    {
        label: '野田支店',
        src: 'branch_new/noda/index.html'
    },
    {
        label: '関東コールド支店',
        src: 'branch_new/kantou_cold/index.html'
    },
    {
        label: '柏支店',
        src: 'branch_new/kashiwa/index.html'
    },
    {
        label: '埼玉支店',
        src: 'branch_new/saitama/index.html'
    },
    {
        label: '埼玉西支店',
        src: 'branch_new/saitama_west/index.html'
    },
    {
        label: '良品営業所',
        src: 'branch_new/ryouhin/index.html'
    },
    {
        label: '羽生支店',
        src: 'branch_new/hanyu/index.html'
    },
    {
        label: '羽生整備工場',
        src: 'https://fujimaintenanceteam.com/mnt_fuji_hanyu.html'
    },
    {
        label: '所沢支店',
        src: 'branch_new/tokorozawa/index.html'
    },
    {
        label: '群馬支店',
        src: 'branch_new/gunma/index.html'
    },
    {
        label: '羽田デル営業所',
        src: 'branch_new/haneda_dell/index.html'
    },
    {
        label: '厚木支店',
        src: 'branch_new/atsugi/index.html'
    },
    {
        label: '相模原準備室',
        src: 'branch_new/sagamihara/index.html'
    },
]