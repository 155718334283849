export default [
    {
        label: '岡山支店',
        src: 'branch_new/okayama/index.html'
    },
    {
        label: '岡山浅口支店',
        src: 'branch_new/okayama_asaguchi/index.html'
    },
    {
        label: '福山支店',
        src: 'branch_new/fukuyama/index.html'
    },
    {
        label: '東広島支店',
        src: 'branch_new/hiroshima_east/index.html'
    },
    {
        label: '広島支店',
        src: 'branch_new/hiroshima/index.html'
    },
    {
        label: '米子支店',
        src: 'branch_new/yonago/index.html'
    },
    {
        label: '出雲支店',
        src: 'branch_new/izumo/index.html'
    },
    {
        label: '山口支店',
        src: 'branch_new/yamaguchi/index.html'
    },
    {
        label: '徳島支店',
        src: 'branch_new/tokushima/index.html'
    },
    {
        label: '高松支店',
        src: 'branch_new/takamatsu/index.html'
    },
    {
        label: '高松整備工場',
        src: 'https://fujimaintenanceteam.com/mnt_fuji_takamatsu.html'
    },
    {
        label: '松山支店',
        src: 'branch_new/matsuyama/index.html'
    },
]