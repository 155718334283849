<template>
    <div class="trucks">
        <div v-for="content, index in trucksContent" :key="index"
            class="flex flex-col md:flex-row mb-3 md:mb-0">
            <img class="mr-2" :src="content.src" :alt="content.alt" />
            <div class="flex flex-col justify-center">
                <div class="text-xs md:text-2xs lg:text-xs">{{ content.text1 }}</div>
                <div class="text-xs md:text-2xs lg:text-xs">{{ content.text2 }}</div>
                <div>
                    <span class="text-xl text-custom-orange">{{ content.total }}</span>
                    <span class="">台</span>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import { trucksContent } from './content.js';

export default {
    data() {
        return {
            trucksContent: trucksContent
        }
    },
}
</script>