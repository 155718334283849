<template>
    <div class="truck-total">
        <div class="text-4xl mb-2">納車待ち車両</div>

        <div class="flex justify-center items-center mb-1">
        <span class="text-5xl">{{ content.total }}</span>
        <span class="text-2xl">台</span>
        </div>

        <span class="border border-custom-red text-xs px-2 py-1">
        {{ `${content.updatedDate}現在` }}
        </span>

        <div class="mt-3 text-2xs">
        新車は発注から納車まで1年以上掛かりますので
        </div>

        <div class="text-2xs mb-1">{{ `${content.planDate}までの納車計画です` }}</div>

        <!-- <a class="used-trucks-button" href="https://fuji-usedtrucks.com/" target="_blank">
            <button>&gt;&gt;中古車販売はこちら&lt;&lt;</button>
        </a> -->
    </div>   
</template>

<script>
import truckTotal from './content.js';

export default {
    data() {
        return {
            content: truckTotal
        }
    },
}
</script>