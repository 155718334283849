<template>
  <ul class="nav-menu">
    <li class="nav-item">
      <span class="cursor-pointer" 
        @click="$emit('modal-content', 'company.html')"
      >
        企業情報
      </span>
    </li>

    <dropdown-item
      :label="'事業所案内'"
      :menuItems="content.branchInfoMenu"
    />

    <dropdown-item
      :label="'サービス案内'"
      :menuItems="content.servicesMenu"
    />

    <dropdown-item
      :label="'資料集'"
      :menuItems="content.documentsMenu"
    />

    <li class="nav-item">
      <span class="cursor-pointer" 
        @click="$emit('modal-content', 'request/request.html')"
      >
        お問い合わせ
      </span>
    </li>
  </ul>
</template>

<script>
import DropdownItem from "./DropdownItem.vue";
import content from "./content.js";

export default {
  components: {
    DropdownItem,
  },
  data() {
    return {
      content: content
    }
  },
};
</script>