<template>
    <div class="carousel-container bg-fujitransport">
        <div class="glide">
            <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides items-center">
                <li v-for="content, index in carouselContent" :key="index"
                    class="glide__slide"
                >
                    <img :src="content.src +'?v1'" :alt="content.alt" />
                </li>

            </ul>
            </div>

            <div class="glide__bullets" data-glide-el="controls[nav]">
            <button v-for="src, index in carouselContent" :key="index" 
                class="glide__bullet"
                :data-glide-dir="'=' + index">
            </button>

            </div>
        </div>
    </div>    
</template>

<script>
import Glide from "@glidejs/glide";
import carouselContent from './content.js';

export default {
    data() {
        return {
            carouselContent: carouselContent
        }
    },
    mounted() {
        new Glide(".glide", {
        autoplay: 5000,
        }).mount();
    },    
}
</script>