<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header"></slot>
        <!-- <button type="button" class="btn-close" @click="close">x</button> -->
      </header>

      <section class="modal-body">
        <slot name="body">
          <div class="iframe-holder">
            <iframe :src="content ? getSRC() : ''" frameborder="0"></iframe>
          </div>
        </slot>
      </section>

      <footer class="modal-footer">
        <slot name="footer"></slot>
        <button type="button" class="footer-btn-close" @click="close">
          閉じる
        </button>
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../scss/modal.scss";
</style>

<script>
export default {
  props: ["content"],
  components: {},
  data() {
    return {
      root: "https://fujitransport.com/",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getSRC() {
      let src = this.content;
      if (!src.startsWith("https://")) {
        //some submenus have a full address from other domain, so for that case this prevents of attach root.
        src = this.root + src;
      }
      return src;
    },
  },
};
</script>