<template>
  <main class="relative">
    <modal
      v-show="isModalVisible"
      @close="closeModal"
      :content="modalContent"
    />

    <div class="bg-fujitransport-mono">
      <div class="nav-top bg-fujitransport"></div>
    </div>

    <header>
      <div class="bg-gray-50">
        <nav>
          <svg
            class="hamburger"
            @click="toggleMobileMenu"
            viewBox="0 0 100 80"
            width="20"
            height="20"
            fill="rgb(55, 65, 81)"
          >
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>

          <div class="flex">
            <fuji-transport-logo />

            <div class="nav-title flex flex-col justify-center text-sm ml-2">
              <div>FUJI TRANSPORT COMPANY LIMITED</div>
              <div>フジトランスポート株式会社</div>
            </div>
          </div>

          <dropdown @modal-content="showModal" />
        </nav>
      </div>

      <div class="bg-fujitransport-mono">
        <carousel />
      </div>
    </header>

    <div class="bg-gray-200">
      <social-media />

      <section class="fuji-holdings main-section">
        <div class="title" style="margin-bottom: 1rem">FUJI HOLDINGS GROUP</div>
        <div class="flex flex-col md:flex-row">
          <company-logos />

          <div class="fuji-map">
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1qj8QI47ajc68iovhek8FDSzvLC3wM9sI"
              width="600"
              height="450"
              allow="fullscreen"
            ></iframe>
          </div>
        </div>
        <hr class="mt-3" />
      </section>

      <section class="bg-white px-5 home-banner main-section relative">
        <a href="https://fujitransport-recruit.net/jobfind-pc/" target="_blank">
          <img
            class="mx-auto cursor-pointer"
            src="../../../assets/img/recruitment2024.png"
          />
        </a>
        <!-- the following image link  wasn't updated so I made it translucid the user sees new design that is part of the banner behind with updated 2024 version link.-->
        <a
          href="https://job.mynavi.jp/24/pc/search/corp209014/outline.html"
          target="_blank"
        >
          <img
            class="new-graduates"
            style="opacity: 0"
            src="../../../assets/img/new-graduates.png"
          />
        </a>

        <hr class="mt-6" />
      </section>

      <section
        class="
          trucks-section
          flex flex-col
          md:flex-row
          px-6
          bg-white
          items-center
          md:items-start
          pt-6
        "
      >
        <img
          class="mx-auto mb-6"
          src="../../../assets/img/trucks_total/truck-numbers_2023_07_18.jpg"
        />
        <!-- <truck-total />    -->
        <!-- momentarialy replaced by image   -->
        <!-- <trucks /> -->
      </section>

      <section class="bg-white px-5 pb-3 home-banner main-section relative">
        <hr class="mb-3" />
        <a href="https://fuji-usedtrucks.com/" target="_blank">
          <img
            class="mx-auto cursor-pointer"
            src="../../../assets/img/used-trucks-banner2024.png"
          />
        </a>
      </section>
      <!-- new banner JNS -->
      <section class="bg-white px-5 pb-3 home-banner main-section relative">
        <hr class="mb-3" />
        <a href="https://www.izacall-jns.com/izatra/" target="_blank">
          <img
            class="mx-auto cursor-pointer"
            src="../../../assets/img/izacall_bunner.jpg"
          />
        </a>
      </section>

      <section class="news main-section">
        <div class="text-xl px-2">NEWS</div>

        <hr />

        <div class="iframe-holder">
          <iframe src="//fujitransport.com/news.html" frameborder="0"></iframe>
        </div>

        <hr />
      </section>

      <footer class="text-center">
        © Copyright {{ year }} - Fuji Transport Company Limited
      </footer>
    </div>
  </main>
</template>

<script>
import FujiTransportLogo from "../shared/FujiTransportLogo.vue";
import Modal from "../shared/modal/Modal.vue";
import Dropdown from "../shared/dropdown/Dropdown.vue";
import Carousel from "../shared/carousel/Carousel.vue";
import CompanyLogos from "../shared/companyLogos/CompanyLogos.vue";
import TruckTotal from "../shared/truckTotal/TruckTotal.vue";
import Trucks from "../shared/trucks/Trucks.vue";
import SocialMedia from "../shared/socialMedia/SocialMedia.vue";

export default {
  components: {
    FujiTransportLogo,
    Modal,
    Dropdown,
    Carousel,
    CompanyLogos,
    TruckTotal,
    Trucks,
    SocialMedia,
  },
  data() {
    return {
      isModalVisible: false,
      body: null,
      modalContent: null,
      modalBackdrop: null,
      mobileBreakpoint: 768, // equivalent to tailwind css md: breakpoint
    };
  },
  computed: {
    year() {
      const dateNow = new Date();

      return dateNow.getFullYear();
    },
  },
  mounted() {
    this.body = document.body;
    this.modalBackdrop = document.querySelector(".modal-backdrop");
    this.modal = document.querySelector(".modal");

    this.modal.addEventListener("click", function (event) {
      event.stopPropagation();
    });

    this.modalBackdrop.addEventListener("click", this.closeModal);

    window.addEventListener("resize", this.changeModalIframeSize);
    window.addEventListener("resize", this.closeMobileDropdownIfWindowIsLarge);

    this.changeModalIframeSize();
    this.closeMobileDropdownIfWindowIsLarge();

    this.initDropdowns(".dropdown");
    this.initDropdowns(".sub-dropdown");
  },
  methods: {
    showModal(src) {
      this.modalContent = src;
      this.hideBodyOverflow();
      this.isModalVisible = true;
    },
    closeModal() {
      this.restoreBodyOverflow();
      this.isModalVisible = false;
      this.modalContent = null;
    },
    hideBodyOverflow() {
      this.body.classList.remove("overflow-auto");
      this.body.classList.add("overflow-hidden");
    },
    restoreBodyOverflow() {
      this.body.classList.remove("overflow-hidden");
      this.body.classList.add("overflow-auto");
    },
    changeModalIframeSize() {
      // Changed to adjusting .iframe-holder instead of the iframe itself due to iOS Safari issues
      const iframe = document.querySelector(".modal-body .iframe-holder");
      const iframeWidth = 830; // declare max iframe width here
      const iframeHeight = 720; // declare max iframe height here

      const dimensions = [
        {
          type: "width",
          iframe: iframe,
          measurement: window.innerWidth,
          max: iframeWidth,
          threshold: iframeWidth + 40, // this is the window width in which the iframe will begin to get narrower
        },
        {
          type: "height",
          iframe: iframe,
          measurement: window.innerHeight,
          max: iframeHeight,
          threshold: iframeHeight + 112, // this is the window height in which the iframe will begin to get shorter
        },
      ];

      dimensions.forEach((dimension) =>
        this.changeModalIframeDimension(dimension)
      );
    },
    changeModalIframeDimension(dimension) {
      if (dimension.measurement < dimension.threshold) {
        const difference = dimension.threshold - dimension.measurement;

        dimension.iframe.style[dimension.type] = `${
          dimension.max - difference
        }px`;
      } else {
        dimension.iframe.style[dimension.type] = `${dimension.max}px`;
      }
    },
    toggleMobileMenu() {
      const navMenu = document.querySelector(".nav-menu");

      if (navMenu.classList.contains("open-mobile-menu")) {
        navMenu.classList.remove("open-mobile-menu");

        this.closeAllDropdownMenus(".sub-dropdown-menu");
        this.closeAllDropdownMenus(".dropdown-menu");
      } else {
        navMenu.classList.add("open-mobile-menu");
      }
    },
    closeMobileDropdownIfWindowIsLarge() {
      if (window.innerWidth > this.mobileBreakpoint + 1) {
        const navMenu = document.querySelector(".nav-menu");

        if (navMenu.classList.contains("open-mobile-menu")) {
          navMenu.classList.remove("open-mobile-menu");

          this.closeAllDropdownMenus(".sub-dropdown-menu");
          this.closeAllDropdownMenus(".dropdown-menu");
        }
      }
    },
    initDropdowns(className) {
      const dropddowns = document.querySelectorAll(className);

      for (const dropdown of dropddowns) {
        dropdown.addEventListener("click", (e) =>
          this.toggleDropdownMenu(e, dropdown, `${className}-menu`)
        );
      }
    },
    toggleDropdownMenu(e, dropdown, className) {
      e.stopPropagation();
      const dropdownMenu = dropdown.querySelector(className);

      if (dropdownMenu.classList.contains("show")) {
        dropdownMenu.classList.remove("show");
      } else {
        this.closeAllDropdownMenus(className);
        dropdownMenu.classList.add("show");
      }
    },
    closeAllDropdownMenus(className) {
      const dropdownMenus = document.querySelectorAll(className);

      for (const dropdownMenu of dropdownMenus) {
        dropdownMenu.classList.remove("show");
      }
    },
  },
};
</script>