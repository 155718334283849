<template>
    <div class="logos">
        <div v-for="content, index in companyLogosContent" :key="index">
            <a :href="content.href ? content.href : '#'"
            target="_blank">
                <img :src="content.src" :alt="content.alt" />
            </a>
            
            <a :href="content.href ? content.href : '#'"
                target="_blank">
                <div>{{ content.label }}</div>         
            </a>   
        </div>
    </div>
</template>

<script>
import companyLogosContent from './content.js';

export default {
    data() {
        return {
            companyLogosContent: companyLogosContent
        }
    }
 }
</script>