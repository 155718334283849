export default [
    {
        label: '採用情報',
        type: 'LINK',
        href: 'https://fujitransport-recruit.net'
    },
    {
        label: '新規入社の流れ',
        type: 'LINK',
        href: 'https://fujitransport.com/pdf/steptoentry.pdf'
    },
    {
        label: '車両検索',
        type: 'MODAL',
        src: 'cgi-bin/car/index.html'
    },
    {
        label: 'YouTuber',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/YouTuber.pdf'
    },
    {
        label: 'シミジュントラック',
        type: 'LINK',
        href: 'https://fuji-usedtrucks.com'
    },
    {
        label: '車両の見える化',
        type: 'MODAL',
        src: 'cgi-bin/car/index.html'
    },
    {
        label: '安全ブログ',
        type: 'MODAL',
        src: 'branch_new/anzen/blog.html'
    },
    {
        label: '沿革',
        type: 'MODAL',
        src: 'history/index.html'
    },
    {
        label: 'エアカーゴ',
        type: 'MODAL',
        src: 'air_container.html'
    },
    {
        label: 'CNG車両',
        type: 'MODAL',
        src: './pdf/cng_truck.pdf'
    },
    {
        label: 'ISO39001',
        type: 'MODAL',
        src: 'ISO/ISO39001.html'
    },
    {
        label: '荷物保険',
        type: 'MODAL',
        src: 'insurance.html'
    },
    {
        label: 'ターンオーバー',
        type: 'MODAL',
        src: 'turn_over/turn_over.html'
    },
    {
        label: '冷凍車',
        type: 'MODAL',
        src: 'cold_chain/cold_chain.html'
    },
    {
        label: '空調車',
        type: 'MODAL',
        src: 'reitou.html'
    },
    {
        label: '運輸安全マネ',
        type: 'MODAL',
        src: 'management/management.html'
    },
    {
        label: '富士 24の強み',
        type: 'MODAL',
        src: 'strengths/strengths.html'
    },
    {
        label: '先輩からの声',
        type: 'MODAL',
        src: 'senpai/senpai8.html'
    },
    {
        label: '社員満足の取組み',
        type: 'MODAL',
        src: 'satisfied/satisfied.html'
    },
    {
        label: '軽油残量計',
        type: 'LINK',
        href: 'http://intank.fuji-webservice.com'
    },
    {
        label: '外国語ページ',
        type: 'modal',
        src: 'foreign_language/index.html'
    },
]