import face from '../../../../assets/img/company_logos/face.png';
import link from '../../../../assets/img/company_logos/link.png';
import shizuoka from '../../../../assets/img/company_logos/shizuoka.png';
// import kenun from '../../../../assets/img/company_logos/kenun.png';
import shikoku from '../../../../assets/img/company_logos/shikoku.png';
import trj from '../../../../assets/img/company_logos/trj.png';
import towa from '../../../../assets/img/company_logos/towa.png';
import kanki from '../../../../assets/img/company_logos/kanki.png';
// import hokuriku from '../../../../assets/img/company_logos/hokuriku.jpg';
// import sanko from '../../../../assets/img/company_logos/sanko.jpg';
// import hyuga from '../../../../assets/img/company_logos/hyuga.jpg';
import kyushu from '../../../../assets/img/company_logos/kyushu.png';
import imc from '../../../../assets/img/company_logos/imc.png';
import senopro from '../../../../assets/img/company_logos/senopro.png';
import smc from '../../../../assets/img/company_logos/smc.png';
import fmt from '../../../../assets/img/company_logos/fmt.png';
import ktm from '../../../../assets/img/company_logos/ktm.png';
import fujiex from '../../../../assets/img/company_logos/fuji-ex.png';
import sogo from '../../../../assets/img/company_logos/sogo.png';

export default [
    {
        href: 'http://www.fujiaircargo.com/',
        src: face,
        alt: 'フジエアカーゴ',
        label: 'フジエアカーゴ'
    },
    {
        href: 'http://www.linknetwork.co.jp/',
        src: link,
        alt: 'リンクネットワーク',
        label: 'リンクネットワーク'
    },
    {
        href: 'http://www.shizuoka-transport.com/',
        src: shizuoka,
        alt: '静岡運送',
        label: '静岡運送'
    },
    // {
    //     href: 'http://www.kenlogi.com/',
    //     src: kenun,
    //     alt: '県運',
    //     label: '県運'
    // },
    {
        href: 'http://shikokutransport.com/',
        src: shikoku,
        alt: '四国トランスポート',
        label: '四国トランスポート',
    },
    {
        href: 'http://tr-japan.co.jp/',
        src: trj,
        alt: 'トレーラージャパン',
        label: 'トレーラージャパン'
    },
    {
        href: 'http://www.towa-cargo.co.jp/',
        src: towa,
        alt: 'トーワカーゴ',
        label: 'トーワカーゴ',
        // alt: '東和運送',
        // label: '東和運送'
    },
    {
        href: 'http://www.kankitransport.com/',
        src: kanki,
        alt: '関汽運輸',
        label: '関汽運輸'
    },
    // {
    //     href: 'https://hokurikutransport.com/',
    //     src: hokuriku,
    //     alt: '北陸トランスポート',
    //     label: '北陸トランスポート'
    // },
    {
        href: 'http://fujiexpress.jp/',
        src: fujiex,
        alt: 'フジエクスプレス',
        label: 'フジエクスプレス'
    },
    // {
    //     href: 'https://hyugatransport.com/',
    //     src: hyuga,
    //     alt: '日向商運',
    //     label: '日向商運'
    // },
    {
        href: 'http://kyushutransport.com/',
        src: kyushu,
        alt: '九州トランスポート',
        label: '九州トランスポート'
    },
    {
        href: 'https://imc-tokio-jp.secure-web.jp/',
        src: imc,
        alt: '保険管理センター',
        label: '保険管理センター'
    },
    {
        href: 'https://www.senoprotrucks.com/',
        src: senopro,
        alt: 'セノプロトラックス',
        label: 'セノプロトラックス'
    },
    {
        href: 'https://www.anzen-smc.com/',
        src: smc,
        alt: '安全マネジメント',
        label: '安全マネジメント'
    },
    {
        href: 'https://fujimaintenanceteam.com/index.html',
        src: fmt,
        alt: '富士車輌整備',
        label: '富士車輌整備'
    },
    {
        href: 'https://www.kanto-ts.com/',
        src: ktm,
        alt: '関東トラック整備',
        label: '関東トラック整備'
    },
    {
        href: 'https://www.gredcl.com/',
        src: sogo,
        alt: '総合不動産開発株式会社',
        label: '総合不動産開発株式会社'
    },
]