export default [
    // {
    //   label: '大阪流入規制',
    //   type: 'MODAL',
    //   src: 'osaka_control/osaka_control.html'
    // },
    // {
    //   label: '社内統計調査',
    //   type: 'MODAL',
    //   src: 'toukei/toukei.html'
    // },
    // {
    //   label: 'グリーン営業認証',
    //   type: 'MODAL',
    //   src: 'news/green/green.html'
    // },
    {
        label: '給油施設一覧',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/intank_hour.pdf'
    },
    {
        label: 'ターンオーバー',
        type: 'MODAL',
        src: 'turn_over/turn_over.html'
    },
    {
        label: 'スワップボディー',
        type: 'LINK',
        href: 'https://fujitransport.com/pdf/swapbody.pdf'
    },
    {
        label: 'ダブルデッキ',
        type: 'LINK',
        href: 'https://fujitransport.com/pdf/doubledeck.pdf'
    },
    {
        label: 'エアサス',
        type: 'MODAL',
        src: 'air_suspension.html'
    },
    {
        label: '運転適性検査',
        type: 'MODAL',
        src: 'tekisei/tekisei2.html'
    },
    {
        label: 'IAAレポート2008',
        type: 'MODAL',
        src: 'gallery/iaa_album2008/index.html'
    },
    {
        label: 'IAAレポート2012',
        type: 'MODAL',
        src: 'gallery/iaa_album2012/index.html'
    },
    {
        label: 'IAAレポート2014',
        type: 'MODAL',
        src: 'gallery/iaa_album2014/index.html'
    },
    {
        label: 'IAAレポート2016',
        type: 'MODAL',
        src: 'gallery/iaa_album2016/index.html'
    },
    {
        label: 'IAAレポート2018',
        type: 'MODAL',
        src: 'gallery/iaa_album2018/index.html'
    },
    {
        label: '運送約款',
        type: 'LINK',
        href: 'https://fujitransport.com/pdf/unsou_yakkana.pdf'
    },
    {
        label: 'タイヤ交換方法',
        type: 'MODAL',
        src: 'tire_movie.html'
    },
    {
        label: '西郷どん写真集',
        type: 'MODAL',
        src: 'photo_album/saigou'
    },
    {
        label: '社会貢献活動',
        type: 'MODAL',
        src: 'social_works/social_works.html'
    },
    {
        label: '女性活躍推進法行動計画',
        type: 'LINK',
        href: 'https://fujitransport.com/pdf/jyoseikatsuyaku.pdf'
    },
    {
        label: '女性の活躍に関する情報公表',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/jyosei_katsuyaku.pdf'
    },
    {
        label: '社次世代育成支援行動計画',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/jisedai.pdf'
    },

    {
        label: '男性労働者の育児休業取得率',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/ikukyu.pdf'
    },
    {
        label: '健康宣言・管理方針',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/kenkou_sengen.pdf'
    },
    {
        label: '国民保護に関する業務計画',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/kokuminhogo.pdf'
    },
    {
        label: '防災業務計画',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/bousai.pdf'
    },
    {
        label: 'プライバシーポリシー',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/privacy_policy.pdf'
    },
    {
        label: 'SDGsへの取組',
        type: 'LINK',
        href: 'https://www.fujitransport.com/sdgs/index.html'
    },
    {
        label: 'DX戦略',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/dx_report.pdf'
    },
    {
        label: '健康経営に関するデータ',
        type: 'LINK',
        href: 'https://www.fujitransport.com/pdf/kenkou_keiei.pdf'
    },
];