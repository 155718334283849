import fuso1 from '../../../../assets/img/trucks/fuso1.png';
import fuso2 from '../../../../assets/img/trucks/fuso2.png';
import fuso3 from '../../../../assets/img/trucks/fuso3.jpg';
// import fuso4 from '../../../../assets/img/trucks/fuso4.jpg';
// import hino1 from '../../../../assets/img/trucks/hino1.png';
// import hino2 from '../../../../assets/img/trucks/hino2.png';
// import hino3 from '../../../../assets/img/trucks/hino3.png';
import fuso5 from '../../../../assets/img/trucks/fuso5.jpg';
// import hino4 from '../../../../assets/img/trucks/hino4.png';
import hino5 from '../../../../assets/img/trucks/hino5.png';
import hino6 from '../../../../assets/img/trucks/hino6.png';
// import hino7 from '../../../../assets/img/trucks/hino7.png';
import hino8 from '../../../../assets/img/trucks/hino8.jpg';
import hino9 from '../../../../assets/img/trucks/hino9.png';
import isuzu1 from '../../../../assets/img/trucks/isuzu1.png';

export const trucksContent = [
    {
        src: fuso1,
        alt: '三菱ふそう 10.7L エンジン 10mウィング',
        text1: '10.7L エンジン',
        text2: '10mウィング', // maximum of 2 text lines
        total: '138'
    },
    {
        src: fuso2,
        alt: '三菱ふそう　高床ウィング',
        text1: '高床ウィング',
        total: '7'
    },
    {
        src: hino9,
        alt: '日野 冷凍バン',
        text1: '冷凍バン',
        total: '47'
    },
    // {
    //     src: hino3,
    //     alt: '日野 空調車',
    //     text1: '空調車',
    //     total: '40'
    // },
    {
        src: fuso5,
        alt: '三菱ふそう 空調車',
        text1: '空調車',
        total: '30'
    },
    // {
    //     src: hino5,
    //     alt: '日野 航空仕様',
    //     text1: '航空仕様',
    //     total: '3'
    // },
    {
        src: hino6,
        alt: '日野 航空バン',
        text1: '3m航空バン',
        total: '2'
    },
    {
        src: fuso1,
        alt: 'ふそう 10m航空仕様',
        text1: '10m航空仕様',
        total: '30'
    },
    // {
    //     src: isuzu1,
    //     alt: 'イスズ　高床ウィング',
    //     text1: '高床ウィング',
    //     total: '5'
    // },    
    {
        src: hino8,
        alt: '日野　高床ウィング',
        text1: '高床ウィング',
        total: '200'
    },
    {
        src: fuso3,
        alt: '三菱ふそう トレーラーヘッド',
        text1: 'トレーラーヘッド',
        total: '22'
    },
    // {
    //     src: fuso4,
    //     alt: '三菱ふそう 冷凍低床4軸',
    //     text1: '冷凍低床4軸',
    //     total: '20'
    // }   
    {
        src: hino5,
        alt: '日野 中低床ウィング',
        text1: '中低床ウィング',
        total: '49'
    }, 
]

export const getTruckTotal = function () {
    let result = 0;

    trucksContent.forEach((data) => {
        result += parseInt(data.total);
    })

    return result;
}