import tohoku from './branchSubmenuItems/tohoku';
import kanto from './branchSubmenuItems/kanto';
import koshinetsu from './branchSubmenuItems/koshinetsu';
import tokai from './branchSubmenuItems/tokai';
import kansai from './branchSubmenuItems/kansai';
import chugoku from './branchSubmenuItems/chugoku';
import kyushu from './branchSubmenuItems/kyushu';

export default [
  {
    label: '役員紹介',
    type: 'MODAL',
    src: 'officer/officer.html'
  },
  {
    label: 'フジホールディングス',
    type: 'MODAL',
    src: 'branch/fuji_holdings.html'
  },
  {
    label: '本社部門',
    type: 'MODAL',
    src: 'branch_new/honsha_office/index.html'
  },
  {
    label: '営業部',
    type: 'MODAL',
    src: 'branch_new/honsha_eigyo/index.html'
    // src: 'branch/honsha_eigyou.html'
  },
  {
    label: '東北',
    type: 'SUBMENU',
    submenu: tohoku
  },
  {
    label: '関東',
    type: 'SUBMENU',
    submenu: kanto
  },
  {
    label: '甲信越',
    type: 'SUBMENU',
    submenu: koshinetsu
  },
  {
    label: '東海',
    type: 'SUBMENU',
    submenu: tokai
  },
  {
    label: '関西',
    type: 'SUBMENU',
    submenu: kansai
  },
  {
    label: '中・四国',
    type: 'SUBMENU',
    submenu: chugoku
  },
  {
    label: '九州',
    type: 'SUBMENU',
    submenu: kyushu
  },
  {
    label: '安全統括部',
    type: 'MODAL',
    src: 'branch_new/anzen/index.html'
  },
  {
    label: '富士車輌整備',
    type: 'LINK',
    href: 'https://fujimaintenanceteam.com/index.html'
  },
  {
    label: '関東トラック整備',
    type: 'LINK',
    href: 'https://www.kanto-ts.com'
  },
  {
    label: '月面営業所',
    type: 'MODAL',
    src: 'branch/moon.html'
  },
];